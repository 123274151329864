import React, { useState, useEffect, useRef } from "react";
import bg1 from "../images/bg-1.png";
import bg2 from "../images/Garnr/survey.png";
import { FiSearch } from "react-icons/fi"; // Importing search icon from react-icons
import bg3 from "../images/Garnr/Container-2.png";
import bg4 from "../images/bg-4.png";
import bg5 from "../images/Garnr/Container.png";
import bg6 from "../images/Garnr/Container-7.png";
import bg7 from "../images/Garnr/pop.png";
import bg0 from "../images/Garnr/Card Hover.png";
import rootframe from "../images/Garnr/Root Frame.png";
import message from "../images/message.png";
import name from "../images/Garnr/name.png";
import surveyImage from "../images/Garnr/Survey Image.png";
import surveyImage2 from "../images/Garnr/surveyImage2.png";
import surveyImage3 from "../images/Garnr/surveyImage3.png";
import surveyImage4 from "../images/Garnr/surveyImage42.png";
import horse from "../images/Garnr/horse.png";
import eye from "../images/Garnr/eye.png";
import notes from "../images/Garnr/notes.png";
import burger from "../images/Garnr/burger.png";
import vector from "../images/Vector.png";
import globe from "../images/Garnr/Container-5.png";
import time from "../images/Garnr/Container-6.png";
import voice from "../images/Garnr/Voice & Tone.png";
import text from "../images/Garnr/Container-3.png";
import user from "../images/Garnr/Container-4.png";
import surveyImageonTop from "../images/Garnr/surveyimageontop.png";
import surveyImagetopOfGraph from "../images/Garnr/image2.5.png";
import messageConntainer from "../images/Garnr/message-container.svg";
import messageConntainerIcon from "../images/Garnr/message-icon.svg";
import GarnrLogo from "../images/Garnr/GarnrLogo.svg";
import { gsap } from "gsap";
import preview1 from "../images/Garnr/form/1.png";
import preview2 from "../images/Garnr/form/2.png";
import preview3 from "../images/Garnr/form/3.png";
import preview4 from "../images/Garnr/form/4.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import section3 from "../images/Garnr/sec-3.png";
import windowc from "../images/Garnr/window.png";
import chatbar from "../images/Garnr/chat-bar.png";
import chatbarhover from "../images/hover/chatbarhover.png";

import searchq from "../images/Garnr/searchq.png";
import section3top from "../images/Garnr/section3-top.png";
import multiple from "../images/Garnr/multiple.png";
import promptc from "../images/Garnr/prompt.png";
import discover from "../images/Garnr/discover.png";
import train from "../images/Garnr/train.png";
import formsinSec from "../images/Garnr/fr1.png";
import brain from "../images/Garnr/brain.png";
import fr2 from "../images/Garnr/fr2.png";
import nocom from "../images/Garnr/nocom.png";
import conversationalhover from "../images/hover/conversationalhover.png"
import formsinsecondshover from "../images/hover/formsinsecondshover.png"
import coversationalsurvey from "../images/hover/coversationalsurvey.png"
import trainhover from "../images/hover/trainaihover.png"
import searchqhover from "../images/hover/searchqhover.png"
import brainhover from "../images/hover/brainhover.png"
import trailhover from "../images/hover/trailhover.png"
import multiplehover from "../images/hover/multiplehover.png"
import nocomplexhover from "../images/hover/nocomplexhover.png"
import form1 from "../images/hover/form1.png"
import form2 from "../images/hover/form2.png"
import form3 from "../images/hover/form3.png"
import form4 from "../images/hover/form4.png"
import form5 from "../images/hover/form5.png"
import finalframe from "../images/Garnr/finalframe.png";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const HomePage = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  // Refs for animation targets
  const messageIconRef = useRef(null);
  const messageContainerRef = useRef(null);
  const discoverTextRef = useRef(null);
  const promptTextRef = useRef(null);
  const textContainerRef = useRef(null);
  const butterflyRef = useRef(null);
  const gridItemsRef = useRef([]);

  const handleButtonClick = () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: { y: ".target-section", offsetY: 50 },
    });

    // Texts Animation
    if (textContainerRef.current) {
      gsap.fromTo(
        textContainerRef.current,
        { y: 100, opacity: 0 },
        { y: 0, opacity: 1, duration: 1.5, ease: "power2.out" }
      );
    }

    // Butterfly and Grid Items Animation (After Texts)
    gsap.fromTo(
      [butterflyRef.current, ...gridItemsRef.current].filter(Boolean), // filter out null elements
      { y: 100, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
        stagger: 0.2,
        delay: 1,
      } // Added delay
    );
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
  };

  useEffect(() => {
    // Initial animation on page load (if needed)
    if (textContainerRef.current) {
      gsap.fromTo(
        textContainerRef.current,
        { y: 100, opacity: 0 },
        { y: 0, opacity: 1, duration: 1.5, ease: "power2.out" }
      );
    }
  
    gsap.fromTo(
      [butterflyRef.current, ...gridItemsRef.current].filter(Boolean),
      { y: 100, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 1.5,
        ease: "power2.out",
        stagger: 0.2,
        delay: 1,
      }
    );
  
    // Scroll-triggered animation for the message icon
    if (messageIconRef.current && messageContainerRef.current) {
      const startAnimation = messageIconRef.current.getBoundingClientRect().top;
      const endAnimation = messageContainerRef.current.getBoundingClientRect().top;
  
      gsap.to(messageIconRef.current, {
        x: () =>
          messageContainerRef.current.getBoundingClientRect().left -
          messageIconRef.current.getBoundingClientRect().left,
        y: () =>
          messageContainerRef.current.getBoundingClientRect().top -
          messageIconRef.current.getBoundingClientRect().top,
        scrollTrigger: {
          trigger: messageIconRef.current,
          start: "top top",
          end: () => "+=" + (endAnimation - startAnimation),
          scrub: true,
        },
      });
    }
  
    // Detect scroll to bottom of the page and trigger last section opening
    ScrollTrigger.create({
      trigger: document.documentElement, // or use a specific element
      start: "bottom bottom",
      onEnter: () => {
        const val = document.getElementsByClassName('make-visible');
        for (var i = 0; i < val.length; i++) {
          val[i].style.display = 'block';
        }
        document.getElementById('make-hidden').style.display = "none";
      },
    });
  
  }, []);
  

  return (
    <div className="app">
      {/* desktop ui start */}
      <div className="hidden md:block relative min-h-screen bg-gray-50 flex flex-col items-center justify-center text-center overflow-hidden">
        {/* Main Text Section */}
        <div className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
          <img src={GarnrLogo} alt="Garnr Logo" />
        </div>

        <div className=" hidden md:block absolute top-2/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
          <h1 className="homepage-text hidden md:block text-lg md:text-2xl lg:text-4xl">
            Conversational AI Moderated Voice Forms & Surveys.
          </h1>
          <button
            onClick={handleButtonClick}
            className="mt-4 bg-gray-800 text-white py-2 px-6 rounded-full shadow-lg hover:bg-gray-700 transition ease-in-out duration-200"
          >
            Request Access
          </button>
        </div>
        <div className="absolute inset-0 overflow-hidden hidden md:block">
          {/* Background Images */}
          <img
            src={bg0}
            alt="Background 1"
            className="h-20 w-auto mt-5 md:mt-8 lg:mt-10 object-cover"
          />
          <img
            src={bg1}
            alt="Background 1"
            className="mt-4 h-20 w-auto md:h-20 lg:h-[25rem] object-cover"
          />
          <img
            src={surveyImagetopOfGraph}
            alt="Background 2"
            className="slanted-sm bottom-20 z-40 absolute h-20 w-auto md:h-20 object-cover"
          />
          <img
            src={bg3}
            alt="Background 3"
            className="mt-[32px] slanted-opp-sm h-20 w-auto md:h-20 object-cover"
          />
          <img
            src={globe}
            alt="Background 4"
            className="mt-4 slanted h-12 w-12 absolute right-1/4 top-[10px] md:h-16 lg:h-20 object-contain"
          />
          <img
            src={time}
            alt="Background 5"
            className="mt-4 slanted h-12 w-12 absolute left-[100px] top-[10px] md:h-16 lg:h-20 object-contain"
          />
          <img
            src={voice}
            alt="Background 6"
            className="mt-4 slanted h-12 w-12 absolute right-[500px] top-[100px] md:h-16 lg:h-20 object-contain"
          />
          <img
            src={text}
            alt="Background 7"
            className="mt-4 slanted-opp h-48 w-48 absolute left-[300px] top-[-10px] md:h-48 lg:h-48 object-contain"
          />
          <img
            src={rootframe}
            alt="Background 8"
            className="slanted-sm opacity-60 absolute bottom-10 left-1/3"
            style={{
              height: "80px",
            }}
          />
          <img
            src={user}
            alt="Background 9"
            className="slanted-sm opacity-60 absolute bottom-20 left-1/4"
            style={{
              height: "50px",
            }}
          />
          <img
            src={bg5}
            alt="Background 10"
            className="absolute top-0 right-0 w-1/4 h-1/4 mt-5 object-contain hidden md:block"
          />
          <img
            src={message}
            alt="Message Icon"
            ref={messageIconRef}
            className="absolute top-20 mt-12 right-0 w-17 h-17 md:w-32 md:h-32 lg:w-36 lg:h-36 object-contain slanted-image-opposite"
          />
          <img
            src={bg6}
            alt="Background 11"
            className="absolute top-20 right-0 w-40 h-40 md:w-48 md:h-48 lg:w-56 lg:h-56 mt-40 object-contain"
          />
          <img
            src={bg7}
            alt="Background 12"
            className="absolute opacity-50 right-0 bottom-0 slanted-image hidden md:block w-1/4 h-1/4"
          />
        </div>
      </div>
      {/* desktop ui end */}

      {/* responsive first section */}
      <div
        className="app bg-image md:hidden relative bg-gray-50 flex flex-row items-center justify-center text-center"
        style={{ height: "100vh" }}
      >
        <div className="bg-img md:hidden">
          <div className="flex justify-center mb-5">
            <img src={vector} className="mr-3" alt="Vector" />
          </div>
          <h1 className="homepage-text-sm ">
            Conversational AI Moderated Voice Forms & Surveys.
          </h1>
          <button
            onClick={handleButtonClick}
            className="mt-4 bg-gray-800 text-white py-2 px-6 z-40 relative rounded-full shadow-lg hover:bg-gray-700 transition ease-in-out duration-200"
          >
            Request Access
          </button>
        </div>
        <div className="absolute inset-0 overflow-hidden">
          {/* Background Circles */}
          <div className="absolute top-0 left-0 w-40 h-40 bg-pink-300 opacity-30 rounded-full filter blur-2xl"></div>
          <div className="absolute bottom-0 right-0 w-60 h-60 bg-blue-300 opacity-30 rounded-full filter blur-2xl"></div>
        </div>
      </div>
      {/* responsive first section end */}

      {/* responsive second section */}
      <div className="flex justify-center bg-gray-50  target-section" style={{}}>
        <div className="absolute">
          <img src={preview3} className="animate__animated animate__fadeIn set-1 rp-img hidden slanted-img" style={{ top: '270px', right: '550px', transform: 'rotate(-10deg)' }} />
          <img src={preview1} className="animate__animated animate__fadeIn set-2 rp-img hidden slanted-img" style={{ top: '400px', right: '550px', transform: 'rotate(-10deg)' }} />
          <img src={preview2} className="animate__animated animate__fadeIn set-2 rp-img hidden slanted-img" style={{ top: '380px', right: '450px', transform: 'rotate(10deg)' }} />
          <img src={preview4} className="animate__animated animate__fadeIn set-1 rp-img hidden slanted-img" style={{ top: '440px', right: '550px', transform: 'rotate(10deg)' }} />

        </div>
        <div className="absolute">
          <img src={preview3} className="set-2 rp-img animate__animated animate__fadeIn hidden slanted-img" style={{ top: '270px', left: '550px', transform: 'rotate(-10deg)' }} />
          <img src={preview1} className="set-1 rp-img animate__animated animate__fadeIn hidden slanted-img" style={{ top: '400px', left: '550px', transform: 'rotate(-10deg)' }} />
          <img src={preview2} className="set-1 rp-img  animate__animated animate__fadeIn hidden slanted-img" style={{ top: '380px', left: '450px', transform: 'rotate(10deg)' }} />
          <img src={preview4} className="set-2 rp-img animate__animated animate__fadeIn hidden slanted-img" style={{ top: '440px', left: '550px', transform: 'rotate(10deg)' }} />

        </div>
        <div ref={textContainerRef} className="max-w-4xl mx-auto p-8 dis-c">

          <h1 ref={discoverTextRef} className="dis">
            Discover the{" "}
          </h1>
          <h1 ref={promptTextRef} className="dis">
            latest features!
          </h1>
          <p className="dis-2">
            Write a prompt to create a form or try any of these prompts to get
            started...
          </p>

          <div className="flex justify-end mb-8">
            <div
              ref={messageContainerRef}
              className="px-4 py-2 rounded-lg flex items-center"
            >
              <img
                src={messageConntainer}
                alt="Message Container"
                className="mr-3"
              />
              <img
                src={messageConntainerIcon}
                alt="Message Container Icon"
                className="relative top-7 right-8"
              />
            </div>
          </div>

          <div className=" mb-8">
            <p>
              <span className="ab">🦋 </span>These are the most popular{" "}
            </p>
            <p className="ml-7 ab">templates for a survey form! </p>
          </div>

          {/* Grid Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div
              onMouseLeave={() => {
                const val = document.getElementsByClassName('set-2');
                for (var i = 0; i < val.length; i++) {
                  val[i].style.display = 'none';
                }
                const opp = document.getElementsByClassName('set-1');
                for (var i = 0; i < opp.length; i++) {
                  opp[i].style.display = 'none';
                }
              }}
              onMouseOver={() => {
                const val = document.getElementsByClassName('set-2');
                for (var i = 0; i < val.length; i++) {
                  val[i].style.display = 'block';
                }
                const opp = document.getElementsByClassName('set-1');
                for (var i = 0; i < opp.length; i++) {
                  opp[i].style.display = 'none';
                }
              }}
              ref={(el) => (gridItemsRef.current[0] = el)}
              className="relative  p-4 rounded-lg transition duration-300 ease-in-out hover:bg-yellow-100 hover:shadow-lg cursor-pointer"
            >
              <img
                src={surveyImage}
                alt="Employee Engagement Survey"
                className="rounded-lg mb-4"
              />
              <img src={notes} alt="Survey Notes" className="rounded-lg mb-4" />
              <h3 className="font-semibold">Employee Engagement Survey</h3>
              <p>
                Design a survey to measure employee satisfaction and engagement.
              </p>
            </div>
            <div
              ref={(el) => (gridItemsRef.current[1] = el)}
              className="relative bg-gray-100 p-4 rounded-lg transition duration-300 ease-in-out hover:bg-yellow-100 hover:shadow-lg cursor-pointer"
              onMouseLeave={() => {
                const val = document.getElementsByClassName('set-2');
                for (var i = 0; i < val.length; i++) {
                  val[i].style.display = 'none';
                }
                const opp = document.getElementsByClassName('set-1');
                for (var i = 0; i < opp.length; i++) {
                  opp[i].style.display = 'none';
                }
              }}
              onMouseOver={() => {
                const val = document.getElementsByClassName('set-1');
                for (var i = 0; i < val.length; i++) {
                  val[i].style.display = 'block';
                }
                const opp = document.getElementsByClassName('set-2');
                for (var i = 0; i < opp.length; i++) {
                  opp[i].style.display = 'none';
                }
              }}
            >
              <img
                src={surveyImage2}
                alt="Market Research Survey"
                className="rounded-lg mb-4"
              />
              <img
                src={horse}
                alt="Market Research"
                className="rounded-lg mb-4"
              />
              <h3 className="font-semibold">Market Research Survey</h3>
              <p>
                Develop a survey to understand customer needs and market trends.
              </p>
            </div>
            <div
              ref={(el) => (gridItemsRef.current[2] = el)}
              className="relative bg-gray-100 p-4 rounded-lg transition duration-300 ease-in-out hover:bg-yellow-100 hover:shadow-lg cursor-pointer"
              onMouseLeave={() => {
                const val = document.getElementsByClassName('set-2');
                for (var i = 0; i < val.length; i++) {
                  val[i].style.display = 'none';
                }
                const opp = document.getElementsByClassName('set-1');
                for (var i = 0; i < opp.length; i++) {
                  opp[i].style.display = 'none';
                }
              }}
              onMouseOver={() => {
                const val = document.getElementsByClassName('set-2');
                for (var i = 0; i < val.length; i++) {
                  val[i].style.display = 'block';
                }
                const opp = document.getElementsByClassName('set-1');
                for (var i = 0; i < opp.length; i++) {
                  opp[i].style.display = 'none';
                }
              }}
            >
              <img
                src={surveyImage3}
                alt="Event Feedback Survey"
                className="rounded-lg mb-4"
              />
              <img src={eye} alt="Event Feedback" className="rounded-lg mb-4" />
              <h3 className="font-semibold">Event Feedback Survey</h3>
              <p>
                Create a form to gather feedback from attendees of a recent
                event.
              </p>
            </div>
            <div
              ref={(el) => (gridItemsRef.current[3] = el)}
              className="relative bg-gray-100 p-4 rounded-lg transition duration-300 ease-in-out hover:bg-yellow-100 hover:shadow-lg cursor-pointer"
              onMouseLeave={() => {
                const val = document.getElementsByClassName('set-2');
                for (var i = 0; i < val.length; i++) {
                  val[i].style.display = 'none';
                }
                const opp = document.getElementsByClassName('set-1');
                for (var i = 0; i < opp.length; i++) {
                  opp[i].style.display = 'none';
                }
              }}
              onMouseOver={() => {
                const val = document.getElementsByClassName('set-1');
                for (var i = 0; i < val.length; i++) {
                  val[i].style.display = 'block';
                }
                const opp = document.getElementsByClassName('set-2');
                for (var i = 0; i < opp.length; i++) {
                  opp[i].style.display = 'none';
                }
              }}
            >
              <img
                src={surveyImage4}
                alt="Customer Success Survey"
                className="rounded-lg mb-4"
              />
              <img
                src={burger}
                alt="Survey Preferences"
                className="rounded-lg mb-4"
              />
              <h3 className="font-semibold">Customer Success Survey</h3>
              <p>Create a survey form on preferences for packaged food.</p>
            </div>
          </div>
        </div>
      </div>
      {/* second section end */}

      {/* third section */}
      <div className="flex justify-center bg-gray-50 hidden md:block">

        <div className="flex justify-center bg-gray-50">
          <img src={windowc} className="animate__animated animate__slideInUp hidden make-visible mt-11 change-position" />

          <div id="add-class" className=" flex mt-10 max-w-4xl mx-auto mb-5 p-8 dis-c" style={{
            height: "100vh"
          }}>
            <div className="hidden make-visible z-10 " >
              <div className="last-section px-10 animate__animated animate__slideInUp animate__delay-1s" style={{ position: 'absolute', left: "35%" }} >
                <img src={section3} className="z-10 mt-10 animate__animated animate__slideInUp animate__delay-1" />
                <div className="section-2-1 mt-3">
                  Discover the latest features!
                </div>
                <div className="section-2-2 text-center mt-4">
                  Begin by creating a form or browse through our
                </div>
                <div className="section-2-2-1 text-center">
                  range of prompts to jumpstart your project.
                </div>
                <div className="flex mt-3">
                  <img
                    onMouseOut={() => {
                      document.getElementById('chatbarhover').style.display = "none"
                      document.getElementById('chatbar').style.display = "block"
                      document.getElementById('formsinsec').src = formsinSec
                      document.getElementById('chatbar-message').style.display = "none"


                    }}
                    onMouseOver={() => {
                      document.getElementById('chatbarhover').style.display = "block"
                      document.getElementById('chatbar').style.display = "none"
                      document.getElementById('formsinsec').src = formsinsecondshover
                      document.getElementById('chatbar-message').style.display = "block"

                    }} className="cursor-pointer mr-2 " src={formsinSec} id="formsinsec" alt="" srcset="" />
                  <img
                    onMouseOut={() => {
                      document.getElementById('coversationHover').style.display = "none"
                      document.getElementById('conversation').style.display = "block"

                      document.getElementById('conv').src = fr2
                      document.getElementById('coversationHover-message').style.display = "none"



                    }}
                    onMouseOver={() => {
                      document.getElementById('coversationHover').style.display = "block"
                      document.getElementById('conversation').style.display = "none"
                      document.getElementById('conv').src = coversationalsurvey
                      document.getElementById('coversationHover-message').style.display = "block"

                    }}
                    className="cursor-pointer" id="conv" src={fr2} alt="" srcset="" />
                </div>
                <div className="flex mt-3">
                  <img
                    onMouseOut={() => {
                      document.getElementById('searchqhover').style.display = "none"
                      document.getElementById('searchq').style.display = "block"

                      document.getElementById('train').src = train

                      document.getElementById('searchqhover-message').style.display = "none"

                    }}
                    onMouseOver={() => {
                      document.getElementById('searchqhover').style.display = "block"
                      document.getElementById('searchq').style.display = "none"
                      document.getElementById('train').src = trainhover
                      document.getElementById('searchqhover-message').style.display = "block"


                    }}
                    className="cursor-pointer mr-2" id="train" src={train} alt="" srcset="" />
                  <img
                    onMouseOut={() => {
                      document.getElementById('trailhover').style.display = "none"
                      document.getElementById('trail').style.display = "block"

                      document.getElementById('brain').src = brain
                      document.getElementById('trailhover-message').style.display = "none"

                    }}
                    onMouseOver={() => {
                      document.getElementById('trailhover').style.display = "block"
                      document.getElementById('trail').style.display = "none"
                      document.getElementById('brain').src = brainhover

                      document.getElementById('trailhover-message').style.display = "block"

                    }}
                    className="cursor-pointer" id="brain" src={brain} alt="" srcset="" />
                </div>
                <div className="mt-3 mb-4">
                  <img
                    onMouseOut={() => {
                      document.getElementById('multiplehover').style.display = "none"
                      document.getElementById('multiple').style.display = "block"

                      document.getElementById('nocom').src = nocom

                      document.getElementById('multiplehover-message').style.display = "none"

                    }}
                    onMouseOver={() => {
                      document.getElementById('multiplehover').style.display = "block"
                      document.getElementById('multiple').style.display = "none"
                      document.getElementById('nocom').src = nocomplexhover
                      document.getElementById('multiplehover-message').style.display = "block"


                    }}
                    className="cursor-pointer" id="nocom" src={nocom} alt="" srcset="" />
                </div>
              </div>

              {/*  */}
              <div className="">
                <img id="chatbar" src={chatbar} className="chatbar  animate__animated animate__fadeInRight chat-bar" />
                <img id="chatbarhover" src={chatbarhover} className="chatbar hidden  animate__animated animate__pulse chat-bar" />
                <img id="chatbar-message" src={form1} className="chatbar hidden  animate__animated animate__pulse chat-bar-message" />

                <img id="searchq" src={searchq} className="change-width animate__animated animate__fadeInRight searchq" />
                <img id="searchqhover" src={searchqhover} className="change-width animate__animated animate__pulse searchq" />
                <img id="searchqhover-message" src={form3} height={100} className="change-width animate__animated animate__pulse searchq-message" />

                <img id="conversation" src={section3top} className=" animate__animated animate__fadeInLeft section-3-top" />
                <img id="coversationHover" src={conversationalhover} width={200} className=" hidden animate__animated animate__pulse section-3-top" />
                <img id="coversationHover-message" src={form2} className=" hidden animate__animated animate__pulse section-3-top-message" />

                <img id="trail" src={promptc} className=" animate__animated animate__fadeInLeft text-container" />
                <img id="trailhover" src={trailhover} width={400} className=" hidden animate__animated animate__pulse text-container" />
                <img id="trailhover-message" src={form4} width={350} className=" hidden animate__animated animate__pulse text-container-message" />

                <img id="multiple" src={multiple} className="change-width animate__animated animate__fadeInLeft multiple" />
                <img id="multiplehover" src={multiplehover} className="hidden change-width animate__animated animate__pulse multiple" />
                <img id="multiplehover-message" src={form5} className="hidden change-width animate__animated animate__pulse multiple-message" />

              </div>
            </div>
            <div className="flex justify-center items-center">

              <img id="make-hidden" src={section3} onClick={() => {
                const val = document.getElementsByClassName('make-visible');
                for (var i = 0; i < val.length; i++) {
                  val[i].style.display = 'block';
                }
                document.getElementById('make-hidden').style.display = "none"
              }} style={{ position: 'absolute', cursor: "pointer", right: "48%" }} />
            </div>
          </div>
          
        </div>
       
      </div>
     {/* final section */}
     {/* <div className="input-box">
      <div></div>
          <img src={finalframe} alt="" srcset="" height={200} />
        </div> */}
     {/* end of final section */}
    </div>
  );
};

export default HomePage;
