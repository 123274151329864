import logo from './logo.svg';
import './App.css';
import HomePage from './Components/Home';

function App() {
  return (
    <HomePage/>
  );
}

export default App;
